<template>
  <b-modal
    :id="id"
    :ref="refModal"
    title="Đổi mật khẩu"
    ok-title="Lưu"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col>
            <!-- password -->
            <b-form-group
              label="Mật khẩu cũ"
            >
              <template
                v-slot:label
              >
                Mật khẩu cũ
                <span
                  class="text-danger"
                >
                  (*)
                </span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="modalOldPassword"
                    v-model="itemLocal.oldPassword"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false : null"
                    name="password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <!-- password -->
            <b-form-group
              label="Mật khẩu mới"
            >
              <template
                v-slot:label
              >
                Mật khẩu mới
                <span
                  class="text-danger"
                >
                  (*)
                </span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Mật khẩu mới"
                vid="newPassword"
                rules="required|password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="modalNewPassword"
                    v-model="itemLocal.newPassword"
                    class="form-control-merge"
                    :type="newPasswordFieldType"
                    :state="errors.length > 0 ? false : null"
                    name="newPassword"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="newPasswordToggleIcon"
                      class="cursor-pointer"
                      @click="toggleNewPasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <!-- confirm password -->
            <b-form-group
              label="Xác nhận mật khẩu"
            >
              <template
                v-slot:label
              >
                Xác nhận mật khẩu
                <span
                  class="text-danger"
                >
                  (*)
                </span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Xác nhận mật khẩu"
                vid="confirmPassword"
                rules="required|password|confirmed:newPassword"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="modalConfirmPassword"
                    v-model="itemLocal.confirmPassword"
                    class="form-control-merge"
                    :type="passwordConfirmFieldType"
                    :state="errors.length > 0 ? false : null"
                    name="confirmPassword"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordConfirmToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordConfirmVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import { togglePasswordConfirmVisibility, toggleNewPasswordVisibility } from '@/layouts/mixins/forms';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import useChangePasswordModal from './useChangePasswordModal';

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility, togglePasswordConfirmVisibility, toggleNewPasswordVisibility],
  props: {
    doctor: {
      type: Object,
      default: null,
    },
    id: {
      type: String,
      default: 'modal-change-password',
    },
  },
  data() {
    return {
      required,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    passwordConfirmToggleIcon() {
      return this.passwordConfirmFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    newPasswordToggleIcon() {
      return this.newPasswordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
  },
  setup({ emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      resetItemLocal,
      resetModal,
      avatarChanged,

      onSubmit,
    } = useChangePasswordModal(emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      resetItemLocal,
      resetModal,
      onSubmit,
      avatarChanged,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>
