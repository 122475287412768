<template>
  <div>
    <b-nav-item-dropdown
      right
      toggle-class="d-flex align-items-center dropdown-user-link"
      class="dropdown-user"
    >
      <template #button-content>
        <div class="d-sm-flex d-none user-nav">
          <p
            class="user-name font-weight-bolder mb-0"
            style="max-width: 200px; text-overflow:ellipsis; overflow: hidden;"
          >
            {{ userData.full_name || '' }}
          </p>
          <span
            v-if="userData.role"
            class="user-role"
          >{{ userData.role.display_name || '' }}</span>
        </div>
        <b-avatar
          size="40"
          :src="userData.avatar"
          variant="light-primary"
        >
          <feather-icon
            v-if="!userData.avatar"
            icon="UserIcon"
            size="22"
          />
        </b-avatar>
      </template>

      <b-dropdown-item
        :to="{ name: 'profile' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="UserIcon"
          class="mr-50"
        />
        <span>Thông tin TK</span>
      </b-dropdown-item>
      <b-dropdown-item
        v-b-modal.modal-change-password
      >
        <feather-icon
          size="16"
          icon="LockIcon"
          class="mr-50"
        />
        <span>Đổi mật khẩu</span>
      </b-dropdown-item>

      <b-dropdown-item
        link-class="d-flex align-items-center"
        @click="logout"
      >
        <feather-icon
          size="16"
          icon="LogOutIcon"
          class="mr-50"
        />
        <span>Đăng xuất</span>
      </b-dropdown-item>
    </b-nav-item-dropdown>
    <change-password-modal />
  </div>
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BAvatar,
  VBModal,
} from 'bootstrap-vue';
import { initialAbility } from '@/libs/acl/config';
import useJwt from '@/auth/jwt/useJwt';
import { avatarText } from '@core/utils/filter';
import ChangePasswordModal from '@/views/profile/view/ChangePasswordModal.vue';

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,

    ChangePasswordModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
    };
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage
      localStorage.removeItem('userData');

      // Reset ability
      this.$ability.update(initialAbility);

      // Redirect to login page
      this.$router.push({ name: 'auth-login' });
    },
  },
};
</script>
