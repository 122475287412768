import { ref } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import md5 from 'md5';
import userStoreModule from '../userStoreModule';

export default function useChangePasswordModal(emit, refFormObserver) {
  const STORE_MODULE_NAME = 'user';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, userStoreModule);
  }

  const toastification = toast();
  const blankItem = {
    newPassword: '',
    confirmPassword: '',
    oldPassword: '',
  };
  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(blankItem));
  };

  const resetModal = () => {
    resetItemLocal();
  };

  const onSubmit = async bvModalEvt => {
    try {
      bvModalEvt.preventDefault();

      const success = await refForm.value.validate();
      if (!success) {
        return;
      }

      const data = {
        old_password: md5(itemLocal.value.oldPassword),
        new_password: md5(itemLocal.value.newPassword),
      };

      console.log(data);

      store
        .dispatch('user/changePassword', data)
        .then(response => { emit('on-item-updated', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastUpdateSuccess();
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return {
    refModal,
    refForm,
    itemLocal,
    resetItemLocal,
    resetModal,
    onSubmit,
  };
}
